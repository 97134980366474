<template>
  <div class="sidebar">
    <div class="sidebar__list">
      <router-link
        v-for="(page, index) in content.user.pages"
        v-bind:class="[
          page.title === content.page
            ? 'sidebar__link sidebar__link_active'
            : 'sidebar__link',
        ]"
        exact-active-class=""
        active-class=""
        v-bind:key="index"
        v-bind:data-name="page.name"
        v-bind:to="page.path"
        @click="logout(page.name)"
      >
        <img
          class="sidebar__icon"
          :src="require(`../assets/images/${page.icon}.svg`)"
          alt=""
        />
        <span class="sidebar__badge" v-if="page.badge && page.badge !== null"
          >1</span
        >
      </router-link>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from "vuex";

  export default {
    name: "Sidebar",
    watch: {
      "$route.path": function () {
        let payload = {
          page: this.$route.name,
        };

        this.changePage(payload);
      },
    },
    computed: {
      ...mapGetters({
        user: "getAuthorizationUser",
        content: "getCommonContent",
      }),
    },
    methods: {
      ...mapMutations(["changePage", "logoutAuthorization"]),

      logout(name) {
        if (name === "Logout") {
          this.$router.push("/profile");
          window.location.href = "https://airwet.app";
          localStorage.clear();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sidebar {
    border-radius: 6px;
    background-color: #f0f0f0;
    box-shadow: 4px 4px 4px #dcdcdc, -4px -4px 4px #f7f7f9eb;
    padding: 8px;
    margin-right: 15px;
    display: none;

    @include mediaquery(sm) {
      display: block;
    }

    &__list {
      display: flex;
      flex-direction: column;
    }

    &__link {
      text-decoration: none;
      padding: 12px;
      border-bottom: 1px solid #d4d4d4;
      position: relative;

      &:last-of-type {
        border: 0;
      }

      &_active {
        .sidebar__icon {
          opacity: 1;
        }
      }
    }

    &__icon {
      width: 20px;
      height: 20px;
      opacity: 0.3;
    }

    &__badge {
      position: absolute;
      right: 0;
      top: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 17px;
      min-width: 17px;
      color: #fff;
      background-color: #93e046;
      font-size: 10px;
      border-radius: 10px;
      z-index: 100;
    }
  }
</style>
