import {
  createRouter,
  createWebHistory
} from 'vue-router'

import Objects from "../pages/Objects.vue";
import Profile from "../pages/Profile.vue";


const routes = [{
    path: '/',
    redirect: '/profile'
  },
  {
    path: '/objects',
    name: 'objects',
    component: Objects
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
});


/* router.beforeEach((to, from, next) => {
  const publicPages = [
    '/profile',
    '/objects',
  ];


  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('authorization__user-role');

  if (authRequired && !loggedIn) {
    next('/profile');
  } else {
    next();
  }
}); */

export default router