import axios from 'axios';

export default {
  state: {
    about: {
      phone: {
        value: null,
      },
      email: {
        value: null,
      },
      email_add: {
        type: "email",
        name: "email_add",
        value: null,
        error: null,
        status: "default",
      },
      comment: {
        type: "text",
        name: "comment",
        value: null,
        error: null,
        status: "default",
      },
      cancellation: true,
      validation: false,
      extended: localStorage.getItem('profile__about-extended') === 'true' ? true : false || false,
    },
    systems: {
      list: [],
      extended: localStorage.getItem('profile__systems-extended') === 'true' ? true : false || false,
    },
    shared: {
      cancellation: true,
      validation: false,
      extended: localStorage.getItem('profile__shared-extended') === 'true' ? true : false || false,
    },
    access: {
      password_old: {
        type: "text",
        name: "password_old",
        value: null,
        error: null,
        status: "default",
      },
      password_new: {
        type: "text",
        name: "password_new",
        value: null,
        error: null,
        status: "default",
      },
      password_confirm: {
        type: "text",
        name: "password_confirm",
        value: null,
        error: null,
        status: "default",
      },
      cancellation: true,
      validation: false,
      extended: localStorage.getItem('profile__access-extended') === 'true' ? true : false || false,
    },
    backup: {},
    response: {
      data: {
        "id": 1,
        "username": "test_user1",
        "email": "demo@airwet.app",
        "provider": "local",
        "confirmed": true,
        "blocked": false,
        "first_name": "Иван",
        "last_name": "Иванов",
        "middle_name": "Иванович",
        "phone": "+7 (999) 999-99-99",
        "phone_add": null,
        "comment": "Мастер",
        "email_add": "demo@airwet.app",
        "first_entry": null,
        "acronym": null,
        "position": null,
        "confirmed_role": null,
        "timezone": null,
        "role": {
          "id": 3,
          "type": "user"
        },
        "avatar": {
          "id": 1,
          "file": "demo_avatar.png",
        },
        "contacts": [{
          "id": 50,
          "comment": "Слесарь",
          "email": "petrov@mail.ru",
          "first_name": "Петр",
          "last_name": "Петров",
          "middle_name": "Петрович",
          "phone": "+7 (990) 909-09-22"
        }],
        "systems": [{
          "id": 162,
          "createdAt": "2022-08-24T06:35:07.221Z",
          "updatedAt": "2022-11-01T12:00:02.272Z",
          "active": false,
          "contract": {
            "id": 70,
            "value": "АА-123456"
          },
          "pump": false,
          "valve_in": false,
          "valve_drain": false,
          "type": "home",
          "dealer": null,
          "name": "Офис",
          "address": "Московская обл., г. Москва, ул. Перерва 32",
          "mileage": 0,
          "current_state": null,
          "common_state": null,
          "system_type": null,
          "pump_model": "Pro max",
          "system_architecture": "Лучевая без промывки",
          "system_mode": "Без пауз",
          "pump_block": "WetPRO-18",
          "water_prepare": "Clean-018",
          "date_start": null,
          "date_install": 1665070140,
          "date_warranty": 1665029040,
          "initialized": false,
          "active_user": true,
          "p_in": 0,
          "p_out": 0,
          "pump_rpm": 0,
          "error": [],
          "active_srv": true,
          "shared": true,
          "status": 0,
          "state": 1,
          "timer": 0,
          "manual_control": false,
          "errors": [],
          "extenders": null,
          "timezone": 0,
          "date_maintenance_last": 1665120780,
          "date_maintenance_plan": 1667473980,
          "hum_min": 0,
          "hum_max": 58
        }],
        "shared": [{
          "id": 158,
          "first_name": "Максим",
          "last_name": "Максимов",
          "middle_name": "Максимович",
          "email": "maximov@mail.ru",
          "phone": "+7 (922) 971-62-55",
          "status": "Брат",
          "systems": [{
            "id": 162,
            "address": "Московская обл., г. Москва, ул. Перерва 32"
          }, ]
        }, ],
        "cards": [{
          "id": 22,
          "number": "2812819829182918",
          "holder": "DSJK DD",
          "system": "visa",
          "createdAt": "2022-04-16T07:06:16.712Z",
          "updatedAt": "2022-04-16T07:06:16.712Z",
          "month": 1,
          "year": 2022,
          "code": 212
        }]
      }
    },
  },
  getters: {
    getProfileAbout: state => state.about,
    getProfileSystems: state => state.systems,
    getProfileShared: state => state.shared,
    getProfileAccess: state => state.access,
  },
  mutations: {
    toggleProfileSection(state, payload) {
      state[payload].extended = !state[payload].extended;

      localStorage.setItem(`profile__${payload}-extended`, state[payload].extended);
    },
    updateProfileAbout(state, payload) {
      state.about.id = payload.id;
      state.about.avatar = payload.avatar;
      state.about.first_name = payload.first_name;
      state.about.middle_name = payload.middle_name;
      state.about.last_name = payload.last_name;
      state.about.phone.value = payload.phone;
      state.about.email.value = payload.email;
      state.about.email_add.value = payload.email_add;
      state.about.comment.value = payload.comment;
      state.about.contacts = payload.contacts;

      state.about.contacts.forEach((contact) => {
        contact.first_name = {
          value: contact.first_name,
          error: null,
          status: 'default',
        }

        contact.middle_name = {
          value: contact.middle_name,
          error: null,
          status: 'default',
        }

        contact.last_name = {
          value: contact.last_name,
          error: null,
          status: 'default',
        }

        contact.comment = {
          value: contact.comment,
          error: null,
          status: 'default',
        }

        contact.phone = {
          value: contact.phone,
          error: null,
          status: 'default',
        }

        contact.email = {
          value: contact.email,
          error: null,
          status: 'default',
        }

        contact.changed = false;
      });
    },
    updateProfileSystems(state, payload) {
      state.systems.list = payload;

      state.systems.list.forEach(system => {
        system.name = localStorage.getItem('objects__system-name') || 'Офис';
      });
    },
    updateProfileShared(state, payload) {
      if (payload.shared) {
        state.shared.users = payload.shared;

        state.shared.users.forEach(user => {
          user.first_name = {
            value: user.first_name,
            error: null,
            status: 'default',
          }

          user.middle_name = {
            value: user.middle_name,
            error: null,
            status: 'default',
          }

          user.last_name = {
            value: user.last_name,
            error: null,
            status: 'default',
          }

          user.status = {
            value: user.status,
            error: null,
            status: 'default',
          }

          user.phone = {
            value: user.phone,
            error: null,
            status: 'default',
          }

          user.email = {
            value: user.email,
            error: null,
            status: 'default',
          }

          user.edit = false;

          let systems = JSON.parse(JSON.stringify(state.systems.list));
          let newSystems = [];

          for (let i = 0; i < systems.length; i++) {
            for (let j = 0; j < user.systems.length; j++) {
              if (systems[i].shared === true && systems[i].id === user.systems[j].id) {
                let obj = {
                  id: systems[i].id,
                  name: systems[i].name,
                  address: systems[i].address,
                  shared: systems[i].shared,
                }
                newSystems.push(obj);
              }
            }
          }

          user.systems = newSystems;
        });
      }
    },

    handleProfileChanges(state, payload) {
      function isEmpty(obj) {
        for (let key in obj) {
          return false;
        }
        return true;
      }

      if (payload.event === 'change' || payload.event === 'paste' || payload.event === 'input') {
        if (payload.section === 'about') {
          if (isEmpty(state.backup.about)) {
            state.backup.about = JSON.parse(JSON.stringify(state.about));
          }

          if (payload.contacts) {
            state.about.contacts.forEach(contact => {
              if (contact.id === payload.id) {
                contact[payload.name].value = payload.value;
                contact.changed = true;

                if (payload.value.match(this.state.common.patterns[payload.name])) {
                  contact[payload.name].status = 'valid';
                  contact[payload.name].error = null;
                } else {
                  contact[payload.name].status = 'invalid';
                  contact[payload.name].error = 'Неверный формат';
                }
              }
            });
          } else {
            state.about[payload.name].value = payload.value;

            if (payload.value.match(this.state.common.patterns[payload.name])) {
              state.about[payload.name].status = 'valid';
              state.about[payload.name].error = null;
            } else {
              state.about[payload.name].status = 'invalid';
              state.about[payload.name].error = 'Неверный формат';
            }
          }

          state.about.cancellation = false;
          state.about.validation = true;

          if (state.about.email_add.status === 'invalid' || state.about.comment.status === 'invalid') {
            state.about.validation = false;
          }

          if (state.about.contacts && state.about.contacts.length > 0) {
            state.about.contacts.forEach(contact => {
              for (let key in contact) {
                if (contact[key].status === 'invalid') state.about.validation = false;
              }
            });
          }
        }

        if (payload.section === 'appearance') {
          if (isEmpty(state.backup.appearance)) {
            state.backup.appearance = JSON.parse(JSON.stringify(state.appearance));
          }

          if (payload.value) {
            state.appearance.dark = true;
            this.commit("changeTheme", 'dark');
          } else {
            state.appearance.dark = false;
            this.commit("changeTheme", 'light');
          }

          this.commit("setTheme");

          state.appearance.cancellation = false;
          state.appearance.validation = true;
        }

        if (payload.section === 'shared') {
          if (isEmpty(state.backup.shared)) {
            state.backup.shared = JSON.parse(JSON.stringify(state.shared));
          }

          if (payload.edit) {
            state.shared.users.forEach(user => {
              if (user.id === payload.user) {
                user.edit = true;
              }
            });
          }

          if (payload.users) {
            state.shared.users.forEach(user => {
              if (user.id === payload.id) {
                user[payload.name].value = payload.value;
                user.changed = true;

                if (payload.value.match(this.state.common.patterns[payload.name])) {
                  user[payload.name].status = 'valid';
                  user[payload.name].error = null;
                } else {
                  user[payload.name].status = 'invalid';
                  user[payload.name].error = 'Неверный формат';
                }
              }
            });

            state.shared.cancellation = false;
            state.shared.validation = true;
          }

          if (payload.name === 'checkbox') {
            state.shared.users.forEach(user => {
              if (user.id === payload.user) {
                user.systems.forEach(system => {
                  if (system.id === payload.system) {
                    system.shared = payload.value;
                  }
                });
              }
            });
            state.shared.cancellation = false;
            state.shared.validation = true;
          }

          if (state.shared.users && state.shared.users.length > 0) {
            state.shared.users.forEach(user => {
              for (let key in user) {
                if (user[key].status === 'invalid') state.shared.validation = false;
              }
            });
          }
        }

        if (payload.section === 'access') {
          if (isEmpty(state.backup.access)) {
            state.backup.access = JSON.parse(JSON.stringify(state.access));
          }
          state.access[payload.name].value = payload.value;

          if (payload.name === 'password_new' || payload.name === 'password_confirm') {
            if (payload.name === 'password_new') {
              if (!payload.value.match(this.state.common.patterns[payload.name])) {
                state.access[payload.name].status = 'invalid';
                state.access[payload.name].error = 'Не менее 6 символов, строчные и заглавные буквы, цифры';
              } else if (
                payload.value.match(this.state.common.patterns[payload.name]) &&
                payload.value !== state.access['password_confirm'].value &&
                state.access['password_confirm'].value !== null
              ) {
                state.access[payload.name].status = 'invalid';
                state.access[payload.name].error = 'Пароли не совпадают';
              } else {
                state.access[payload.name].status = 'valid';
                state.access[payload.name].error = null;
              }
            }

            if (payload.name === 'password_confirm') {
              if (!payload.value.match(this.state.common.patterns[payload.name])) {
                state.access[payload.name].status = 'invalid';
                state.access[payload.name].error = 'Не менее 6 символов, строчные и заглавные буквы, цифры';
              } else if (
                payload.value.match(this.state.common.patterns[payload.name]) &&
                payload.value !== state.access['password_new'].value
              ) {
                state.access[payload.name].status = 'invalid';
                state.access[payload.name].error = 'Пароли не совпадают';
              } else {
                state.access[payload.name].status = 'valid';
                state.access[payload.name].error = null;
              }
            }

            if (
              state.access['password_new'].value === state.access['password_confirm'].value &&
              state.access['password_new'].value.match(this.state.common.patterns[payload.name]) &&
              state.access['password_confirm'].value.match(this.state.common.patterns[payload.name])
            ) {
              state.access['password_new'].status = 'valid';
              state.access['password_new'].error = null;
              state.access['password_confirm'].status = 'valid';
              state.access['password_confirm'].error = null;
            }
          } else {
            if (payload.value.match(this.state.common.patterns.password)) {
              state.access[payload.name].status = 'valid';
              state.access[payload.name].error = null;
            } else {
              state.access[payload.name].status = 'invalid';
              state.access[payload.name].error = 'Неверный формат';
            }
          }

          state.access.cancellation = false;
          state.access.validation = true;

          if (
            state.access.password_old.status !== 'valid' ||
            state.access.password_new.status !== 'valid' ||
            state.access.password_confirm.status !== 'valid'
          ) {
            state.access.validation = false;
          }
        }
      }
    },

    resetProfileState(state, payload) {
      if (payload === 'about') {
        state.about.email_add.status = 'default';
        state.about.email_add.error = null;

        state.about.comment.status = 'default';
        state.about.comment.error = null;

        state.about.cancellation = true;
        state.about.validation = false;

        state.backup.about = {};
      }

      if (payload === 'appearance') {
        state.appearance.cancellation = true;
        state.appearance.validation = false;

        state.backup.appearance = {};
      }

      if (payload === 'shared') {
        state.shared.cancellation = true;
        state.shared.validation = false;

        state.backup.shared = {};
      }

      if (payload === 'access') {
        state.access.password_old.status = 'default';
        state.access.password_old.error = null;
        state.access.password_old.value = null;

        state.access.password_new.status = 'default';
        state.access.password_new.error = null;
        state.access.password_new.value = null;

        state.access.password_confirm.status = 'default';
        state.access.password_confirm.error = null;
        state.access.password_confirm.value = null;

        state.access.cancellation = true;
        state.access.validation = false;

        state.backup.access = {};
      }
    },
    cancelProfileChanges(state, payload) {
      if (payload === 'about') {
        state.about.email_add.status = 'default';
        state.about.email_add.error = null;
        state.about.email_add.value = state.backup.about.email_add.value;

        state.about.comment.status = 'default';
        state.about.comment.error = null;
        state.about.comment.value = state.backup.about.comment.value;

        state.about.contacts.forEach((contact, index) => {
          contact.first_name = {
            value: state.backup.about.contacts[index].first_name.value,
            error: null,
            status: 'default',
          }

          contact.middle_name = {
            value: state.backup.about.contacts[index].middle_name.value,
            error: null,
            status: 'default',
          }

          contact.last_name = {
            value: state.backup.about.contacts[index].last_name.value,
            error: null,
            status: 'default',
          }

          contact.comment = {
            value: state.backup.about.contacts[index].comment.value,
            error: null,
            status: 'default',
          }

          contact.phone = {
            value: state.backup.about.contacts[index].phone.value,
            error: null,
            status: 'default',
          }

          contact.email = {
            value: state.backup.about.contacts[index].email.value,
            error: null,
            status: 'default',
          }

          contact.changed = false;
        });

        state.about.cancellation = true;
        state.about.validation = false;

        state.backup.about = {};
      }

      if (payload === 'appearance') {
        state.appearance.dark = state.backup.appearance.dark;

        if (state.appearance.dark) this.commit("changeTheme", 'dark');
        else this.commit("changeTheme", 'light');

        this.commit("setTheme");

        state.appearance.cancellation = true;
        state.appearance.validation = false;

        state.backup.appearance = {};
      }

      if (payload === 'shared') {
        state.shared = state.backup.shared;
        state.shared.cancellation = true;
        state.shared.validation = false;

        state.backup.shared = {};
      }

      if (payload === 'access') {
        state.access.password_old.status = 'default';
        state.access.password_old.error = null;
        state.access.password_old.value = null;

        state.access.password_new.status = 'default';
        state.access.password_new.error = null;
        state.access.password_new.value = null;

        state.access.password_confirm.status = 'default';
        state.access.password_confirm.error = null;
        state.access.password_confirm.value = null;

        state.access.cancellation = true;
        state.access.validation = false;

        state.backup.access = {};
      }
    },
    clearProfileState(state) {
      state.about = {
        phone: {
          value: null,
        },
        email: {
          value: null,
        },
        email_add: {
          type: "email",
          name: "email_add",
          value: null,
          error: null,
          status: "default",
        },
        comment: {
          type: "text",
          name: "comment",
          value: null,
          error: null,
          status: "default",
        },
        cancellation: true,
        validation: false,
        extended: false,
      };
      state.systems = {
        list: [],
        extended: false,
      };
      state.pay = {};
      state.backup = {};
      state.payments = {
        badge: '1 389 руб. 09 коп.',
      };
      state.appearance = {
        cancellation: true,
        validation: false,
        extended: false,
      };
      state.shared = {
        cancellation: true,
        validation: false,
        extended: false,
      };
      state.access = {
        password_old: {
          name: "password_old",
          value: null,
          status: "default",
          error: false,
          errorMessage: "Не менее 6 символов, строчные и заглавные буквы, цифры",
        },
        password_new: {
          name: "password_new",
          value: null,
          status: "default",
          error: false,
          errorMessage: "Не менее 6 символов, строчные и заглавные буквы, цифры",
        },
        password_confirm: {
          name: "password_confirm",
          value: null,
          status: "default",
          error: false,
          errorMessage: "Не менее 6 символов, строчные и заглавные буквы, цифры",
        },
        cancellation: true,
        validation: false,
        extended: false,
      };
    },
  },
  actions: {
    fetchProfile(context) {
      context.commit("showPreloader");

      let payload = {
        visible: true,
        title: 'Уведомление!',
        text: `
              Ваша учетная запись является демонстрационной! Данная учетная запись
              создана для ознакомления с интерфейсом нашего сервиса. Все
              произведенные изменения будут отменены после выхода из системы.
              `,
        control: false,
      };

      context.commit('createNotification', payload);

      setTimeout(() => {
        let response = JSON.parse(localStorage.getItem('profile')) || context.state.response;

        localStorage.setItem('profile', JSON.stringify(response));

        context.commit('updateProfileAbout', response.data);
        context.commit('updateProfileSystems', response.data.systems);
        context.commit('updateProfileShared', response.data);
        context.commit("hidePreloader");
      }, 100);
    },
    addProfileAvatar(context, payload) {
      context.commit("showPreloader");

      let formData = new FormData();
      formData.append("files", payload);

      axios
        .post("https://api.airwet.app/api/upload", formData, this.state.common.headers)
        .then((response) => {
          axios
            .put("https://api.airwet.app/api/users/me", {
              data: {
                avatar: response.data[0].id,
              }
            }, this.state.common.headers)
            .then(() => {
              axios
                .get("https://api.airwet.app/api/users/me", this.state.common.headers)
                .then((response) => {
                  context.commit('updateProfileAbout', response.data);
                  context.commit("hidePreloader");
                })
            })
        })
        .catch((error) => context.commit("handleError", error));
    },
    deleteProfileAvatar(context) {
      context.commit("showPreloader");

      setTimeout(() => {
        context.state.response.data.avatar = null;

        context.commit('updateProfileAbout', context.state.response.data);

        localStorage.setItem('profile', JSON.stringify(context.state.response));
        context.commit("hidePreloader");
      }, 100);
    },
    addProfileContact(context, payload) {
      context.commit("showPreloader");

      setTimeout(() => {
        let response = JSON.parse(localStorage.getItem('profile')) || context.state.response;

        let contact = {
          id: response.data.contacts.length + 1,
          last_name: payload.last_name,
          first_name: payload.first_name,
          middle_name: payload.middle_name,
          comment: payload.comment,
          phone: payload.phone,
          email: payload.email,
        }

        response.data.contacts.push(contact);

        localStorage.setItem('profile', JSON.stringify(response));

        context.commit('updateProfileAbout', response.data);
        context.commit("hidePreloader");
      }, 100);
    },
    deleteProfileContact(context, payload) {
      context.commit("showPreloader");

      setTimeout(() => {
        let response = JSON.parse(localStorage.getItem('profile')) || context.state.response;

        response.data.contacts = response.data.contacts.filter(contact => contact.id !== payload.id);

        localStorage.setItem('profile', JSON.stringify(response));

        context.commit('updateProfileAbout', response.data);
        context.commit("hidePreloader");
      }, 100);
    },
    addProfileUser(context, payload) {
      context.commit("showPreloader");

      setTimeout(() => {
        let response = JSON.parse(localStorage.getItem('profile')) || context.state.response;

        let user = {
          id: response.data.shared.length + 1,
          last_name: payload.last_name,
          first_name: payload.first_name,
          middle_name: payload.middle_name,
          status: payload.status,
          email: payload.email,
          phone: payload.phone,
          systems: [],
        };

        let systems = JSON.parse(JSON.stringify(context.state.systems.list));

        for (let i = 0; i < systems.length; i++) {
          for (let j = 0; j < payload.systems.length; j++) {
            if (systems[i].id === payload.systems[j]) {
              let obj = {
                id: systems[i].id,
                address: systems[i].address,
              };

              user.systems.push(obj);
            }
          }
        }

        response.data.shared.push(user);

        localStorage.setItem('profile', JSON.stringify(response));

        context.commit('updateProfileShared', response.data);
        context.commit("hidePreloader");
      }, 100);
    },
    sendProfileChanges(context, payload) {
      context.commit("showPreloader");

      setTimeout(() => {
        let response = JSON.parse(localStorage.getItem('profile')) || context.state.response;

        if (payload.section === 'about') {
          response.data.email_add = context.state.about.email_add.value;
          response.data.comment = context.state.about.comment.value;

          response.data.contacts.forEach((contact, index) => {
            contact.first_name = context.state.about.contacts[index].first_name.value;
            contact.middle_name = context.state.about.contacts[index].middle_name.value;
            contact.last_name = context.state.about.contacts[index].last_name.value;
            contact.comment = context.state.about.contacts[index].comment.value;
            contact.phone = context.state.about.contacts[index].phone.value;
            contact.email = context.state.about.contacts[index].email.value;
          });

          localStorage.setItem('profile', JSON.stringify(response));

          context.commit('resetProfileState', 'about');
          context.commit('updateProfileAbout', response.data);
        }

        if (payload.section === 'shared') {
          response.data.shared.forEach((user, index) => {
            if (context.state.shared.users[index].edit) {
              user.status = context.state.shared.users[index].status.value;

              let systems = context.state.shared.users[index].systems;

              for (let i = 0; i < systems.length; i++) {
                for (let j = 0; j < user.systems.length; j++) {
                  if (systems[i].shared === false && systems[i].id === user.systems[j].id) {
                    user.systems.splice(j, 1)
                  }
                }
              }
            }
          });

          response.data.shared = response.data.shared.filter(user => user.systems.length > 0)

          localStorage.setItem('profile', JSON.stringify(response));

          context.commit('updateProfileShared', response.data);
          context.commit('resetProfileState', 'shared');
        }

        context.commit("hidePreloader");
      }, 100);
    },
    changeProfilePassword(context) {
      context.commit("showPreloader");

      setTimeout(() => {
        let payload = {
          type: "password",
        };

        context.commit('cancelProfileChanges', 'access');
        context.commit('createPopup', payload);
        context.commit("hidePreloader");
      }, 100);
    }
  }
}