<template>
  <Preloader />
  <Header />
  <Viewer />
  <Modal />
  <Popup />
  <div class="page">
    <Sidebar />
    <router-view />
  </div>
</template>

<script>
  import { mapMutations } from "vuex";

  import Header from "./layout/Header.vue";
  import Modal from "./layout/Modal.vue";
  import Popup from "./layout/Popup.vue";
  import Viewer from "./layout/Viewer.vue";
  import Preloader from "./layout/Preloader.vue";
  import Sidebar from "./layout/Sidebar.vue";

  export default {
    name: "App",
    components: {
      Header,
      Modal,
      Popup,
      Viewer,
      Preloader,
      Sidebar,
    },
    methods: {
      ...mapMutations(["handleError", "collapseDropdowns"]),
    },
    mounted() {
      document.addEventListener(
        "click",
        function (event) {
          let headerNavbar = document.querySelector(".header__navbar");

          if (headerNavbar !== null) {
            if (headerNavbar.classList.contains("header__navbar_visible")) {
              if (
                !event.target.closest(".header__navbar") &&
                !event.target.closest(".header__toggle")
              ) {
                headerNavbar.className = "header__navbar";
              }
            }
          }
        },
        false
      );
    },
  };
</script>

<style lang="scss">
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    font-size: 14px;
    font-family: "Avenir";
    height: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    margin: 0;
    padding: 10px;
    height: 100%;
    min-width: 375px;
    background-color: #f0f0f0;
  }

  button,
  input,
  select,
  textarea,
  a {
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    box-shadow: none;
    -webkit-tap-highlight-color: transparent;
  }

  .page {
    display: flex;
    align-items: flex-start;
  }

  .container {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 20px;
  }

  .dp__main {
    position: relative;
    z-index: $zIndexDatepicker;
  }

  .dp__main input {
    height: 36px !important;
    width: 100%;
    padding-left: 12px;
    padding-right: 10px;
    font-family: "Avenir";
    font-size: 12px;
    border: 1px solid transparent;
    border-radius: 6px;
    background-color: #f0f0f0;
    box-shadow: 4px 4px 4px #dcdcdc, -4px -4px 4px #f7f7f9eb;
    color: #222325;

    &:hover {
      border-color: transparent;
      cursor: auto;
    }
  }
</style>
