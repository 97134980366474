<template>
  <main class="profile">
    <div class="profile__content">
      <Notification />
      <section class="about">
        <div class="about__header" @click="toggleSection('about')">
          <div class="about__main">
            <div class="about__avatar">
              <slot v-if="about.avatar">
                <img
                  class="about__avatar-picture"
                  :src="require(`../assets/images/${about.avatar.file}`)"
                  alt=""
                />
                <button
                  class="about__avatar-delete"
                  @click.stop="deleteAvatar(about.avatar.id)"
                  v-if="false"
                >
                  <img :src="require(`../assets/images/delete.svg`)" alt="" />
                </button>
              </slot>
              <slot v-else>
                <!-- <label class="about__file" @change.stop="addAvatar">
                  <input class="about__file-input" type="file" name="avatar" />
                  <img
                    class="about__file-icon"
                    :src="require(`../assets/images/add.svg`)"
                  />
                </label> -->
                <label class="about__file" @change.stop="addAvatar">
                  <input class="about__file-input" type="file" name="avatar" />
                  <span class="about__file-action">
                    <font-awesome-icon icon="fa-plus" />
                  </span>
                </label>
              </slot>
            </div>
            <div class="about__block">
              <div class="about__title">ФИО владельца</div>
              <Input
                :type="'text'"
                :value="`${about.last_name} ${about.first_name} ${about.middle_name}`"
                :disabled="true"
              />
            </div>
          </div>
          <div class="about__control">
            <button class="about__button" @click.stop="toggleSection('about')">
              <span
                :class="[
                  about.extended
                    ? 'about__button-icon about__button-icon_rotate'
                    : 'about__button-icon',
                ]"
              ></span>
            </button>
          </div>
        </div>
        <div class="about__body" v-show="about.extended">
          <div class="about__owner">
            <div class="about__block">
              <div class="about__title">Телефон</div>
              <Input :value="about.phone.value" :disabled="true" />
            </div>
            <div class="about__block">
              <div class="about__title">Электронная почта</div>
              <Input :value="about.email.value" :disabled="true" />
            </div>
            <div class="about__block">
              <div class="about__title">Эл.почта доп.</div>
              <Input
                :type="about.email_add.type"
                :name="about.email_add.name"
                :value="about.email_add.value"
                :status="about.email_add.status"
                @focus="handleChanges"
                @paste="handleChanges"
                @input="handleChanges"
                @change="handleChanges"
              />
              <span class="about__error" v-if="about.email_add.error">{{
                about.email_add.error
              }}</span>
            </div>
            <div class="about__block">
              <div class="about__title">Комментарий</div>
              <Input
                :type="about.comment.type"
                :name="about.comment.name"
                :value="about.comment.value"
                :status="about.comment.status"
                @focus="handleChanges"
                @paste="handleChanges"
                @input="handleChanges"
                @change="handleChanges"
              />
              <span class="about__error" v-if="about.comment.error">{{
                about.comment.error
              }}</span>
            </div>
            <div class="about__action">
              <Button
                :type="'action'"
                :value="'add'"
                :paint="'gray'"
                @click="addContact"
              />
            </div>
          </div>
          <div
            class="about__contacts"
            v-if="about.contacts && about.contacts.length > 0"
          >
            <div
              class="about__contact"
              v-for="contact in about.contacts"
              :data-id="contact.id"
              :key="contact.id"
            >
              <div class="about__block">
                <div class="about__title">Фамилия</div>
                <Input
                  :type="'text'"
                  :name="'last_name'"
                  :value="contact.last_name.value"
                  :status="contact.last_name.status"
                  @focus="handleChanges"
                  @paste="handleChanges"
                  @input="handleChanges"
                  @change="handleChanges"
                />
                <span class="about__error" v-if="contact.last_name.error">{{
                  contact.last_name.error
                }}</span>
              </div>
              <div class="about__block">
                <div class="about__title">Имя</div>
                <Input
                  :type="'text'"
                  :name="'first_name'"
                  :value="contact.first_name.value"
                  :status="contact.first_name.status"
                  @focus="handleChanges"
                  @paste="handleChanges"
                  @input="handleChanges"
                  @change="handleChanges"
                />
                <span class="about__error" v-if="contact.first_name.error">{{
                  contact.first_name.error
                }}</span>
              </div>
              <div class="about__block">
                <div class="about__title">Отчество</div>
                <Input
                  :type="'text'"
                  :name="'middle_name'"
                  :value="contact.middle_name.value"
                  :status="contact.middle_name.status"
                  @focus="handleChanges"
                  @paste="handleChanges"
                  @input="handleChanges"
                  @change="handleChanges"
                />
                <span class="about__error" v-if="contact.middle_name.error">{{
                  contact.middle_name.error
                }}</span>
              </div>
              <div class="about__block">
                <div class="about__title">Комментарий</div>
                <Input
                  :type="'text'"
                  :name="'comment'"
                  :value="contact.comment.value"
                  :status="contact.comment.status"
                  @focus="handleChanges"
                  @paste="handleChanges"
                  @input="handleChanges"
                  @change="handleChanges"
                />
                <span class="about__error" v-if="contact.comment.error">{{
                  contact.comment.error
                }}</span>
              </div>
              <div class="about__block">
                <div class="about__title">Телефон</div>
                <Input
                  :type="'tel'"
                  :name="'phone'"
                  :value="contact.phone.value"
                  :status="contact.phone.status"
                  @focus="handleChanges"
                  @paste="handleChanges"
                  @input="handleChanges"
                  @change="handleChanges"
                />
                <span class="about__error" v-if="contact.phone.error">{{
                  contact.phone.error
                }}</span>
              </div>
              <div class="about__block">
                <div class="about__title">Электронная почта</div>
                <Input
                  :type="'email'"
                  :name="'email'"
                  :value="contact.email.value"
                  :status="contact.email.status"
                  @focus="handleChanges"
                  @paste="handleChanges"
                  @input="handleChanges"
                  @change="handleChanges"
                />
                <span class="about__error" v-if="contact.email.error">{{
                  contact.email.error
                }}</span>
              </div>
              <div class="about__action">
                <Button
                  :type="'action'"
                  :value="'delete'"
                  :paint="'gray'"
                  @click="deleteContact"
                />
              </div>
            </div>
          </div>
          <div class="about__footer">
            <Button
              :type="'text'"
              :value="'Отмена'"
              :paint="'gray'"
              :disabled="about.cancellation"
              @click="cancelChanges('about')"
            />
            <Button
              :type="'text'"
              :value="'Сохранить изменения'"
              :paint="'blue'"
              :disabled="!about.validation"
              @click="sendChanges('about')"
            />
          </div>
        </div>
      </section>
      <section class="systems" v-if="systems.list.length > 0">
        <div class="systems__header" @click="toggleSection('systems')">
          <div class="systems__main">
            <div class="systems__headline">Мои системы</div>
            <span class="systems__badge">{{ systems.list.length }}</span>
          </div>
          <div class="systems__control">
            <button
              class="systems__button"
              @click.stop="toggleSection('systems')"
            >
              <span
                :class="[
                  systems.extended
                    ? 'systems__button-icon systems__button-icon_rotate'
                    : 'systems__button-icon',
                ]"
              ></span>
            </button>
          </div>
        </div>
        <div class="systems__body" v-show="systems.extended">
          <div class="systems__list">
            <div
              class="systems__system"
              v-for="system in systems.list"
              :id="system.id"
              :key="system.id"
              @click="goToObject"
            >
              <div class="systems__info">
                <div class="systems__name">
                  {{ system.name }} {{ system.contract.value }}
                </div>
                <div class="systems__address">
                  {{ system.address }}
                </div>
              </div>
              <div class="systems__wrapper">
                <slot v-if="system.errors.length > 0">
                  <div class="systems__state systems__state_error">ошибка</div>
                </slot>
                <slot v-else-if="system.active_user && system.active_srv">
                  <div class="systems__state systems__state_active">
                    работает
                  </div>
                </slot>
                <slot v-else-if="!system.active_user && system.active_srv">
                  <div class="systems__state systems__state_inactive">
                    не работает
                  </div>
                </slot>
                <slot v-else>
                  <div class="systems__state systems__state_inactive">
                    не работает
                  </div>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="shared">
        <div class="shared__header" @click="toggleSection('shared')">
          <div class="shared__main">
            <div class="shared__headline">Общий доступ</div>
          </div>
          <div class="shared__control">
            <button
              class="shared__button"
              @click.stop="toggleSection('shared')"
            >
              <span
                :class="[
                  shared.extended
                    ? 'shared__button-icon shared__button-icon_rotate'
                    : 'shared__button-icon',
                ]"
              ></span>
            </button>
          </div>
        </div>
        <div class="shared__body" v-show="shared.extended">
          <div class="shared__add">
            <div class="shared__add-title">Добавить пользователя</div>
            <div class="shared__action">
              <Button
                :type="'action'"
                :value="'add'"
                :paint="'gray'"
                @click="addUser"
              />
            </div>
          </div>
          <div
            class="shared__users"
            v-if="shared.users && shared.users.length > 0"
          >
            <div
              class="shared__user"
              v-for="user in shared.users"
              :data-id="user.id"
              :key="user.id"
            >
              <div class="shared__about">
                <div class="shared__block">
                  <div class="shared__title">Фамилия</div>
                  <Input
                    :type="'text'"
                    :name="'last_name'"
                    :value="user.last_name.value"
                    :status="user.last_name.status"
                    :disabled="true"
                    @focus="handleChanges"
                    @paste="handleChanges"
                    @input="handleChanges"
                    @change="handleChanges"
                  />
                  <span class="shared__error" v-if="user.last_name.error">{{
                    user.last_name.error
                  }}</span>
                </div>
                <div class="shared__block">
                  <div class="shared__title">Имя</div>
                  <Input
                    :type="'text'"
                    :name="'first_name'"
                    :value="user.first_name.value"
                    :status="user.first_name.status"
                    :disabled="true"
                    @focus="handleChanges"
                    @paste="handleChanges"
                    @input="handleChanges"
                    @change="handleChanges"
                  />
                  <span class="shared__error" v-if="user.first_name.error">{{
                    user.first_name.error
                  }}</span>
                </div>
                <div class="shared__block">
                  <div class="shared__title">Отчество</div>
                  <Input
                    :type="'text'"
                    :name="'middle_name'"
                    :value="user.middle_name.value"
                    :status="user.middle_name.status"
                    :disabled="true"
                    @focus="handleChanges"
                    @paste="handleChanges"
                    @input="handleChanges"
                    @change="handleChanges"
                  />
                  <span class="shared__error" v-if="user.middle_name.error">{{
                    user.middle_name.error
                  }}</span>
                </div>
                <div class="shared__block">
                  <div class="shared__title">Статус</div>
                  <Input
                    :type="'text'"
                    :name="'status'"
                    :value="user.status.value"
                    :status="user.status.status"
                    :disabled="!user.edit"
                    @focus="handleChanges"
                    @paste="handleChanges"
                    @input="handleChanges"
                    @change="handleChanges"
                  />
                  <span class="shared__error" v-if="user.status.error">{{
                    user.status.error
                  }}</span>
                </div>
                <div class="shared__block">
                  <div class="shared__title">Телефон</div>
                  <Input
                    :type="'tel'"
                    :name="'phone'"
                    :value="user.phone.value"
                    :status="user.phone.status"
                    :disabled="true"
                    @focus="handleChanges"
                    @paste="handleChanges"
                    @input="handleChanges"
                    @change="handleChanges"
                  />
                  <span class="shared__error" v-if="user.phone.error">{{
                    user.phone.error
                  }}</span>
                </div>
                <div class="shared__block">
                  <div class="shared__title">Электронная почта</div>
                  <Input
                    :type="'email'"
                    :name="'email'"
                    :value="user.email.value"
                    :status="user.email.status"
                    :disabled="true"
                    @focus="handleChanges"
                    @paste="handleChanges"
                    @input="handleChanges"
                    @change="handleChanges"
                  />
                  <span class="shared__error" v-if="user.email.error">{{
                    user.email.error
                  }}</span>
                </div>
                <div class="shared__action">
                  <Button
                    :type="'action'"
                    :value="'edit'"
                    :paint="'gray'"
                    @click="handleChanges"
                  />
                  <Button
                    :type="'action'"
                    :value="'delete'"
                    :paint="'gray'"
                    @click="deleteUser"
                    v-if="false"
                  />
                </div>
              </div>
              <div class="shared__linked">
                <div class="shared__subtitle">Связанные системы</div>
                <div class="shared__systems">
                  <div
                    class="shared__system"
                    v-for="system in user.systems"
                    :id="system.id"
                    :key="system.id"
                  >
                    <div class="shared__system-header">
                      <div class="shared__system-info">
                        <div class="shared__system-name">
                          {{ system.name }}, {{ system.address }}
                        </div>
                      </div>
                      <div class="shared__system-wrapper">
                        <div class="shared__action">
                          <Checkbox
                            :status="system.shared"
                            :disabled="!user.edit"
                            @change="handleChanges"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="shared__footer">
            <Button
              :type="'text'"
              :value="'Отмена'"
              :paint="'gray'"
              :disabled="shared.cancellation"
              @click="cancelChanges('shared')"
            />
            <Button
              :type="'text'"
              :value="'Сохранить изменения'"
              :paint="'blue'"
              :disabled="!shared.validation"
              @click="sendChanges('shared')"
            />
          </div>
        </div>
      </section>
      <section class="access">
        <div class="access__header" @click="toggleSection('access')">
          <div class="access__main">
            <div class="access__headline">Настройка доступа</div>
          </div>
          <div class="access__control">
            <button
              class="access__button"
              @click.stop="toggleSection('access')"
            >
              <span
                :class="[
                  access.extended
                    ? 'access__button-icon access__button-icon_rotate'
                    : 'access__button-icon',
                ]"
              ></span>
            </button>
          </div>
        </div>
        <div class="access__body" v-show="access.extended">
          <div class="access__blocks">
            <div class="access__block">
              <div class="access__title">Введите старый пароль</div>
              <Input
                :type="access.password_old.type"
                :name="access.password_old.name"
                :value="access.password_old.value"
                :status="access.password_old.status"
                @focus="handleChanges"
                @paste="handleChanges"
                @input="handleChanges"
                @change="handleChanges"
              />
              <span class="access__error" v-if="access.password_old.error">{{
                access.password_old.error
              }}</span>
            </div>
            <div class="access__block">
              <div class="access__title">Введите новый пароль</div>
              <Input
                :type="access.password_new.type"
                :name="access.password_new.name"
                :value="access.password_new.value"
                :status="access.password_new.status"
                @focus="handleChanges"
                @paste="handleChanges"
                @input="handleChanges"
                @change="handleChanges"
              />
              <span class="access__error" v-if="access.password_new.error">{{
                access.password_new.error
              }}</span>
            </div>
            <div class="access__block">
              <div class="access__title">Подтвердите пароль</div>
              <Input
                :type="access.password_confirm.type"
                :name="access.password_confirm.name"
                :value="access.password_confirm.value"
                :status="access.password_confirm.status"
                @focus="handleChanges"
                @paste="handleChanges"
                @input="handleChanges"
                @change="handleChanges"
              />
              <span
                class="access__error"
                v-if="access.password_confirm.error"
                >{{ access.password_confirm.error }}</span
              >
            </div>
          </div>
          <div class="access__footer">
            <Button
              :type="'text'"
              :value="'Отмена'"
              :paint="'gray'"
              :disabled="access.cancellation"
              @click="cancelChanges('access')"
            />
            <Button
              :type="'text'"
              :value="'Сохранить изменения'"
              :paint="'blue'"
              :disabled="!access.validation"
              @click="sendChanges('access')"
            />
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
  import { mapGetters, mapMutations, mapActions } from "vuex";

  import Notification from "../layout/Notification";

  import Button from "../components/Button";
  import Checkbox from "../components/Checkbox";
  import Input from "../components/Input";

  export default {
    name: "Profile",
    components: {
      Notification,
      Button,
      Checkbox,
      Input,
    },
    computed: {
      ...mapGetters({
        user: "getAuthorizationUser",
        about: "getProfileAbout",
        systems: "getProfileSystems",
        shared: "getProfileShared",
        access: "getProfileAccess",
      }),
    },
    methods: {
      ...mapMutations([
        "toggleProfileSection",
        "handleProfileChanges",
        "cancelProfileChanges",
        "createModal",
        "createPopup",
      ]),
      ...mapActions([
        "fetchProfile",
        "addProfileAvatar",
        "sendProfileChanges",
        "changeProfilePassword",
      ]),
      toggleSection(section) {
        this.toggleProfileSection(section);
      },
      handleChanges(event) {
        let payload = {};

        payload = {
          section: event.target.closest("section").className,
          event: event.type,
          name: event.target.getAttribute("name"),
          value: event.target.value,
        };

        if (payload.name === "phone") {
          if (event.type === "paste") {
            let value = event.clipboardData.getData("text/plain");

            let pattern =
              /(\+7|8)\s?[\s(]?(\d{3})[\s)]?\s?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;
            let phoneNumbers = value.match(pattern);

            if (phoneNumbers !== null) {
              let correctNumber = phoneNumbers[0].replace(
                pattern,
                "+7 ($2) $3-$4-$5"
              );

              event.target.value = correctNumber;
            }
          } else if (event.type === "keydown" || event.type === "input") {
            let keyCode;
            event.keyCode && (keyCode = event.keyCode);

            if (event.target.selectionStart < 3) {
              event.target.value = `+7 ${event.data}`;
            }

            let mask = "+7 (___) ___-__-__";
            let index = 0;
            let def = mask.replace(/\D/g, "");
            let val = event.target.value.replace(/\D/g, "");
            let newVal = mask.replace(/[_\d]/g, (arg) =>
              index < val.length ? val.charAt(index++) || def.charAt(index) : arg
            );

            index = newVal.indexOf("_");

            if (index !== -1) {
              index < 5 && (index = 3);
              newVal = newVal.slice(0, index);
            }

            let reg = mask
              .substr(0, event.target.value.length)
              .replace(/_+/g, (arg) => `\\d{1,${arg.length}}`)
              .replace(/[+()]/g, "\\$&");

            reg = new RegExp(`^${reg}$`);

            if (
              !reg.test(event.target.value) ||
              event.target.value.length < 5 ||
              (keyCode > 47 && keyCode < 58)
            ) {
              event.target.value = newVal;
            }
          }

          payload.value = event.target.value;
        }

        if (payload.name === "theme") {
          payload.value = event.target.checked;
        }

        if (event.target.closest(".about__contacts")) {
          payload.contacts = true;
          payload.id = +event.target
            .closest(".about__contact")
            .getAttribute("data-id");
        }

        if (event.target.closest(".shared__users")) {
          if (event.target.closest("button")) {
            payload.edit = true;
            payload.user = +event.target
              .closest(".shared__user")
              .getAttribute("data-id");
            payload.event = "change";
          }
          if (event.target.closest(".input")) {
            payload.users = true;
            payload.id = +event.target
              .closest(".shared__user")
              .getAttribute("data-id");
          }

          if (event.target.closest(".checkbox")) {
            payload.name = "checkbox";
            payload.value = event.target.checked;
            payload.user = +event.target
              .closest(".shared__user")
              .getAttribute("data-id");
            payload.system = +event.target
              .closest(".shared__system")
              .getAttribute("id");
          }
        }

        this.handleProfileChanges(payload);
      },
      cancelChanges(section) {
        this.cancelProfileChanges(section);
      },
      sendChanges(section) {
        let payload = {};

        if (section === "about") {
          payload.section = section;

          this.sendProfileChanges(payload);
        }

        if (section === "appearance") {
          payload.section = section;

          this.sendProfileChanges(payload);
        }

        if (section === "shared") {
          payload.section = section;

          this.sendProfileChanges(payload);
        }

        if (section === "access") {
          payload.password_old = this.access.password_old.value;
          payload.password_new = this.access.password_new.value;
          payload.password_confirm = this.access.password_confirm.value;

          this.changeProfilePassword(payload);
        }
      },
      addAvatar(event) {
        this.addProfileAvatar(event.target.files[0]);
      },
      deleteAvatar(id) {
        let payload = {
          type: "avatar",
          params: {
            id: +id,
          },
        };

        this.createPopup(payload);
      },
      addContact() {
        let payload = {
          type: "contact",
          params: {
            last_name: null,
            first_name: null,
            middle_name: null,
            comment: null,
            phone: null,
            email: null,
          },
        };

        this.createModal(payload);
      },
      deleteContact(event) {
        let payload = {
          type: "contact",
          params: {
            id: +event.target.closest(".about__contact").getAttribute("data-id"),
          },
        };

        this.createPopup(payload);
      },
      addUser() {
        let payload = {
          type: "user",
          params: {
            systems: this.systems.list,
            last_name: null,
            first_name: null,
            middle_name: null,
            comment: null,
            status: null,
            phone: null,
            email: null,
            password: null,
          },
        };

        this.createModal(payload);
      },
      goToObject() {
        this.$router.push(`/objects`);
      },
      deleteSystem(event) {
        let payload = {
          type: "system",
          params: {
            id: +event.target.closest(".systems__system").id,
          },
        };

        this.createPopup(payload);
      },
      deleteCard(event) {
        let payload = {
          type: "card",
          params: {
            id: +event.target.closest(".pay__card").getAttribute("data-id"),
          },
        };

        this.createPopup(payload);
      },
      addCard() {
        let payload = {
          type: "card",
          params: {
            holder: null,
            number: null,
            system: null,
            month: null,
            year: null,
            code: null,
          },
        };

        this.createModal(payload);
      },
      makePayment() {
        let payload = {
          type: "payment",
          params: {},
        };

        this.createModal(payload);
      },
    },
    created() {
      this.fetchProfile();
    },
  };
</script>

<style lang="scss" scoped>
  .profile {
    position: relative;
    width: 100%;
    padding-bottom: 20px;

    &__content {
      display: flex;
      flex-wrap: wrap;
    }

    .about {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-radius: 6px;
      background-color: #f0f0f0;
      box-shadow: 4px 4px 4px #dcdcdc, -4px -4px 4px #f7f7f9eb;
      padding: 20px 20px;
      margin-bottom: 20px;

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
        user-select: none;

        input {
          cursor: pointer;
        }

        .about__block {
          width: 100%;
        }

        @include mediaquery(md) {
          .about__block {
            width: 400px;
          }
        }
      }

      &__main {
        display: flex;
        width: 100%;

        @include mediaquery(md) {
          .about__block {
            width: 50%;
          }
        }
      }

      &__avatar {
        width: 60px;
        min-width: 60px;
        height: 60px;
        border-radius: 6px;
        background-color: #f0f0f0;
        box-shadow: 4px 4px 4px #dcdcdc, -4px -4px 4px #f7f7f9eb;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;

        &-picture {
          width: 100%;
          transition: opacity 0.3s;
        }

        &-delete {
          position: absolute;
          background: none;
          padding: 0;
          border: 0;
          opacity: 0;
          width: 36px;
          height: 36px;
          transition: opacity 0.3s;

          img {
            width: 14px;
          }
        }
      }

      &__file {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        width: 36px;
        cursor: pointer;
        overflow-x: hidden;
        -webkit-tap-highlight-color: transparent;

        &-input {
          position: absolute;
          width: 0;
          height: 0;
          opacity: 0;
          overflow: hidden;
          z-index: -1;
        }

        &-icon {
          width: 16px;
        }
      }

      &__control {
        display: flex;
        align-items: center;
        margin-top: 15px;
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__owner {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 10px;

        .about__block {
          width: 100%;
          margin-bottom: 10px;
        }

        @include mediaquery(md) {
          flex-direction: row;

          .about__block {
            width: calc(25% - 19px);
            margin-bottom: 0;

            &:last-of-type {
              width: calc(25% + 27px);
            }
          }
        }
      }

      &__contacts {
        padding: 20px 0;
        margin-top: 20px;
        border-top: 1px solid #e1e1e1;
        border-bottom: 1px solid #e1e1e1;
      }

      &__contact {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-bottom: 10px;

        .about__block {
          width: 100%;
          margin-bottom: 10px;
        }

        @include mediaquery(md) {
          justify-content: flex-start;
          align-items: flex-end;

          .about__block {
            width: calc(33.333333% - 22px);
            margin-right: 10px;
          }

          button {
            margin-bottom: 10px;
          }
        }

        @include mediaquery(xl) {
          flex-wrap: nowrap;

          .about__block {
            width: calc(25% - 19px);
            margin-right: 10px;
            margin-bottom: 0;
          }

          &:last-of-type {
            margin-bottom: 0;
          }

          button {
            margin-bottom: 0;
          }
        }
      }

      &__block {
        position: relative;
      }

      &__title {
        @include title;
      }

      &__error {
        position: absolute;
        left: 0;
        top: 100%;
        font-size: 9px;
        color: #ff8068;
      }

      &__action {
        width: 36px;
        display: flex;
      }

      &__footer {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        margin-top: 20px;

        button {
          width: 100%;
          margin-bottom: 10px;
        }

        @include mediaquery(sm) {
          flex-direction: row;

          button {
            width: auto;
            margin-bottom: 0;

            &:first-of-type {
              margin-right: 10px;
            }
          }
        }
      }
    }

    .systems {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-radius: 6px;
      background-color: #f0f0f0;
      box-shadow: 4px 4px 4px #dcdcdc, -4px -4px 4px #f7f7f9eb;
      padding: 20px 20px;
      margin-bottom: 20px;

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
      }

      &__headline {
        font-size: 12px;
        font-weight: 500;
        color: #222325;
        text-transform: uppercase;
      }

      &__badge {
        margin-left: 5px;
        background-color: #41c6eb;
        color: #fff;
        border-radius: 10px;
        padding: 3px 7px;
        font-size: 10px;
      }

      &__main {
        display: flex;
        align-items: center;
      }

      &__control {
        display: flex;
        align-items: center;
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__list {
        padding-top: 10px;
        display: flex;
        flex-wrap: wrap;
        margin: -5px;
      }

      &__system {
        border-radius: 6px;
        background-color: #f0f0f0;
        box-shadow: 4px 4px 4px #dcdcdc, -4px -4px 4px #f7f7f9eb;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 5px;
        cursor: pointer;

        @include mediaquery(md) {
          width: calc(50% - 10px);
        }
      }

      &__name {
        font-size: 12px;
        font-weight: 500;
        color: #2b1a0a;
      }

      &__address {
        font-size: 10px;
        color: #2b1a0a;
        margin-top: 5px;
      }

      &__wrapper {
        display: flex;
        align-items: center;
      }

      &__state {
        @include state;
      }
    }

    .pay {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-radius: 6px;
      background-color: #f0f0f0;
      box-shadow: 4px 4px 4px #dcdcdc, -4px -4px 4px #f7f7f9eb;
      padding: 20px 20px;
      margin-bottom: 20px;

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
      }

      &__headline {
        font-size: 12px;
        font-weight: 500;
        color: #222325;
        text-transform: uppercase;
      }

      &__badge {
        margin-left: 5px;
        background-color: #41c6eb;
        color: #fff;
        border-radius: 10px;
        padding: 3px 7px;
        font-size: 10px;
      }

      &__main {
        display: flex;
        align-items: center;
      }

      &__control {
        display: flex;
        align-items: center;
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__preview {
        padding-top: 10px;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
        margin-top: -5px;
      }

      &__card {
        border-radius: 6px;
        background-color: #f0f0f0;
        box-shadow: 4px 4px 4px #dcdcdc, -4px -4px 4px #f7f7f9eb;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 5px;

        @include mediaquery(md) {
          width: calc(50% - 10px);
        }

        &-icon {
          width: 46px;
        }

        &-wrapper {
          display: flex;
          align-items: center;
        }

        &-props {
          display: flex;
          align-items: center;
        }

        &-info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-left: 17px;

          @include mediaquery(lg) {
            flex-direction: row;
            align-items: center;
          }
        }

        &-number {
          font-size: 12px;
          font-weight: 500;
          color: #2b1a0a;
        }

        &-holder {
          font-size: 10px;
          color: #2b1a0a;
          text-transform: uppercase;

          @include mediaquery(lg) {
            margin-left: 20px;
          }
        }

        &-expire {
          margin-left: 20px;
          font-size: 10px;
          color: #2b1a0a;
          text-transform: uppercase;
        }
      }

      &__new {
        border-radius: 6px;
        background-color: #f0f0f0;
        box-shadow: 4px 4px 4px #dcdcdc, -4px -4px 4px #f7f7f9eb;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 5px;

        @include mediaquery(md) {
          width: calc(50% - 10px);
        }

        &-title {
          font-weight: 500;
          font-size: 12px;
          margin-left: 18px;
          color: #2b1a0a;
        }
      }
    }

    .payments {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-radius: 6px;
      background-color: #f0f0f0;
      box-shadow: 4px 4px 4px #dcdcdc, -4px -4px 4px #f7f7f9eb;
      padding: 20px 20px;
      margin-bottom: 20px;

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
      }

      &__headline {
        font-size: 12px;
        font-weight: 500;
        color: #222325;
        text-transform: uppercase;
      }

      &__badge {
        margin-left: 5px;
        background-color: #41c6eb;
        color: #fff;
        border-radius: 10px;
        padding: 3px 7px;
        font-size: 10px;
      }

      &__main {
        display: flex;
        align-items: center;
      }

      &__control {
        display: flex;
        align-items: center;
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__preview {
        padding-top: 10px;
        display: flex;
        flex-wrap: wrap;
      }

      &__payment {
        display: flex;
        align-items: flex-end;
        width: 50%;
      }

      &__block {
        width: 100%;
        margin-right: 10px;
      }

      &__title {
        font-size: 9px;
        margin-bottom: 6px;
        color: #9b9b9b;
      }
    }

    .shared {
      @include base;

      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 20px 20px;
      margin-bottom: 20px;

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
      }

      &__headline {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
      }

      &__badge {
        margin-left: 5px;
        background-color: #41c6eb;
        color: #fff;
        border-radius: 10px;
        padding: 3px 7px;
        font-size: 10px;
      }

      &__main {
        display: flex;
        align-items: center;
      }

      &__control {
        display: flex;
        align-items: center;
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__add {
        @include base;

        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 18px;
        padding: 10px;

        &-title {
          font-size: 12px;
          font-weight: 500;
        }
      }

      &__users {
        padding: 10px 0 0;
      }

      &__user {
        @include base;

        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        padding: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &__action {
        display: flex;
      }

      &__about {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        width: 100%;

        .shared__block {
          width: 100%;
          margin-bottom: 10px;
        }

        @include mediaquery(md) {
          justify-content: flex-start;
          align-items: flex-end;

          .shared__block {
            width: calc(33.333333% - 22px);
            margin-right: 10px;
          }

          button {
            margin-bottom: 10px;
          }
        }

        @include mediaquery(xl) {
          flex-wrap: nowrap;

          .shared__block {
            width: calc(25% - 19px);
            margin-right: 10px;
            margin-bottom: 0;
          }

          &:last-of-type {
            margin-bottom: 0;
          }

          button {
            margin-bottom: 0;
          }
        }
      }

      &__block {
        position: relative;
      }

      &__title {
        @include title;
      }

      &__error {
        position: absolute;
        left: 0;
        top: 100%;
        font-size: 9px;
        color: #ff8068;
      }

      &__linked {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
      }

      &__subtitle {
        font-size: 12px;
        font-weight: 500;
        color: #222325;
        margin-bottom: 5px;
      }

      &__systems {
        display: flex;
        flex-wrap: wrap;
        margin-top: 5px;

        display: flex;
        flex-wrap: wrap;
        margin: -5px;
      }

      &__system {
        @include base;

        width: 100%;
        margin: 5px;
        padding: 8px;

        @include mediaquery(md) {
          width: calc(50% - 10px);
        }

        &-header {
          display: flex;
          justify-content: space-between;
        }

        &-info {
          display: flex;
          align-items: center;
          max-width: 80%;
        }

        &-name {
          font-size: 12px;
        }

        &-wrapper {
          display: flex;
          align-items: center;
        }

        &-state {
          @include state;
          display: none;

          margin-right: 15px;
        }
      }

      &__footer {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        margin-top: 20px;

        button {
          width: 100%;
          margin-bottom: 10px;
        }

        @include mediaquery(sm) {
          flex-direction: row;
          button {
            width: auto;
            margin-bottom: 0;

            &:first-of-type {
              margin-right: 10px;
            }
          }
        }
      }
    }

    .access {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-radius: 6px;
      background-color: #f0f0f0;
      box-shadow: 4px 4px 4px #dcdcdc, -4px -4px 4px #f7f7f9eb;
      padding: 20px 20px;

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
      }

      &__headline {
        font-size: 12px;
        font-weight: 500;
        color: #222325;
        text-transform: uppercase;
      }

      &__badge {
        margin-left: 5px;
        background-color: #41c6eb;
        color: #fff;
        border-radius: 10px;
        padding: 3px 7px;
        font-size: 10px;
      }

      &__main {
        display: flex;
        align-items: center;
      }

      &__control {
        display: flex;
        align-items: center;
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__blocks {
        display: flex;
        flex-direction: column;
        padding: 10px 0 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #e1e1e1;

        @include mediaquery(md) {
          flex-direction: row;
        }
      }

      &__block {
        width: 100%;
        margin-bottom: 10px;
        position: relative;

        @include mediaquery(md) {
          width: calc(25% - 8px);
          margin-right: 10px;
          margin-bottom: 0;
        }
      }

      &__title {
        @include title;
      }

      &__error {
        position: absolute;
        left: 0;
        top: 100%;
        font-size: 9px;
        color: #ff8068;
      }

      &__footer {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        margin-top: 20px;

        button {
          width: 100%;
          margin-bottom: 10px;
        }

        @include mediaquery(sm) {
          flex-direction: row;
          button {
            width: auto;
            margin-bottom: 0;

            &:first-of-type {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
</style>