import {
  createStore
} from 'vuex';

import common from './common';
import objects from './objects';
import profile from './profile';

export const store = createStore({
  modules: {
    common,
    objects,
    profile,
  },
})